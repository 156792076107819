/* You can add global styles to this file, and also import other style files */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: NotoSansCJKjp;
  src: url("../../assets/fonts/NotoSansCJKjp-Regular.woff2") format("woff2"), url("../../assets/fonts/NotoSansCJKjp-Regular.ttf") format("truetype");
}
@font-face {
  font-family: NotoSansCJKjp;
  src: url("../../assets/fonts/NotoSansCJKjp-Bold.woff2") format("woff2"), url("../../assets/fonts/NotoSansCJKjp-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: NotoSans;
  src: url("../../assets/fonts/NotoSans-Regular.woff2") format("woff2"), url("../../assets/fonts/NotoSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: NotoSans;
  src: url("../../assets/fonts/NotoSans-Bold.woff2") format("woff2"), url("../../assets/fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
html {
  height: 100vh;
}

body {
  height: 100vh;
  font-size: 14px;
  font-family: NotoSans, NotoSansCJKjp, sans-serif;
  color: #262a33;
  -webkit-text-size-adjust: 100%;
}
body.popup-tooltip-style .k-animation-container {
  width: 300px;
}
body.popup-tooltip-style .k-popup .k-chart-tooltip {
  padding-inline: 9px;
}

a {
  text-decoration: none;
}

button {
  font-family: NotoSans, NotoSansCJKjp, sans-serif;
  cursor: pointer;
  color: #262a33;
}

input:disabled,
textarea:disabled {
  color: inherit;
}

::selection {
  background-color: #ccc !important;
  color: inherit !important;
}

.disabled {
  opacity: 0.5 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.google-tag-manager {
  height: 0;
  width: 0;
  display: none;
  visibility: hidden;
}

.icon-button {
  background: transparent;
  border: 0;
  padding: 0;
  width: 20px;
  height: 20px;
}

.custom-accordion-list-card__variable-label {
  word-break: break-word;
}

.k-grid-header,
.k-grid td,
.k-grid th,
.k-grid .k-hierarchy-cell {
  border: 0;
  text-align: left;
  text-overflow: initial;
  word-break: break-word;
}

* {
  word-break: break-word;
}

[class*=k-link][class*=k-state-disabled] {
  color: inherit !important;
}

/* stylelint-disable-next-line */
kendo-popup {
  z-index: 450 !important;
}