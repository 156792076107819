// リージョンで'_const.scss'の定義を上書きする場合に修正して利用する。
// 全体で利用したい変数を定義する。
// Modify and use when overwriting the definition of'_const.scss' in the region.
// Define the variables you want to use as a whole.
@import 'const';

// Breakpoints for Responsive. Use to '_mixin.scss'.
$breakpoints-max-width: (
  smartphone: 1024px,
  tablet: 1440px,
);

//////////////////////////////////////////////////
// Theme Color (start)

// Base Color
$tc-base: #fff;
$tc-sub1: #bebebe;
$tc-sub2: #e9e9ea;
$tc-accent: #0097e0;

// Status Color
$tc-sts-cool: #1e7eff;
$tc-sts-heat: #f7931e;
$tc-sts-fan: #65d9e6;
$tc-sts-off: #595959;
$tc-sts-error: #ea0303;

$tc-sts-communication-error: #5ebaf8;
$tc-sts-equipment-error: #ffdf00;
$tc-sts-forced-stop: #ea0303;

$tc-sts-warn: #fba800;
$tc-sts-success: #7dbd55;

$tc-sts-color1: #00b050;
$tc-sts-color2: #d23200;

// Font Color
$tc-font-base: #262a33;
$tc-font-sub1: #fff;
$tc-font-sub2: #999;
$tc-font-accent: $tc-accent;
$tc-font-cool: $tc-sts-cool;
$tc-font-heat: $tc-sts-heat;
$tc-font-off: $tc-sts-off;
$tc-font-error: $tc-sts-error;
$tc-font-warn: $tc-sts-warn;
$tc-font-success: $tc-sts-success;
$tc-font-equipment-error: #f6cd00;
$tc-font-fan: $tc-sts-fan;
$tc-font-disable-inactive: #8f9191;
$tc-font-sub3: #e9e9ea;
$tc-font-sub4: #d3d4d6;
$tc-font-sub5: #4db7ea;
$tc-font-sub6: #7b7b7b;
$tc-font-sub7: #ee3a58;
$tc-font-sub8: #949494;
$tc-font-color1: $tc-sts-color1;
$tc-font-color2: $tc-sts-color2;

// Border Color
$tc-border-default: #bbb;
$tc-border-light: #e9e9ea;
$tc-border-dark: #777;
$tc-border-accent: $tc-accent;
$tc-border-cool: $tc-sts-cool;
$tc-border-heat: $tc-sts-heat;
$tc-border-off: $tc-sts-off;
$tc-border-error: $tc-sts-error;
$tc-border-warn: $tc-sts-warn;
$tc-border-success: $tc-sts-success;
$tc-border-color1: $tc-sts-color1;
$tc-border-color2: $tc-sts-color2;

// Shadow Settings
$tc-shadow-horizontal: 0;
$tc-shadow-vertical: 0;
$tc-shadow-blur: 10px;
$tc-shadow-color: rgb(0 0 0 / 15%);
$tc-shadow: $tc-shadow-horizontal $tc-shadow-vertical $tc-shadow-blur $tc-shadow-color;

// Shadow Settings for Drag&Drop
$tc-shadow-for-drag-and-drop: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
  0 3px 14px 2px rgb(0 0 0 / 12%);

// Link Text Color
$tc-link-default: #00f;
$tc-link-default_r: 0;
$tc-link-default_g: 0;
$tc-link-default_b: 255;
$tc-link-visited: #800080;

// Parts Color
$tc-parts-accent: $tc-accent;
$tc-parts-cool: $tc-sts-cool;
$tc-parts-heat: $tc-sts-heat;
$tc-parts-off: $tc-sts-off;
$tc-parts-error: $tc-sts-error;
$tc-parts-warn: $tc-sts-warn;
$tc-parts-fan: $tc-sts-fan;
$tc-parts-success: $tc-sts-success;
$tc-parts-base: $tc-base;
$tc-parts-sub1: $tc-sub1;
$tc-parts-sub2: $tc-sub2;
// チェックボックスやラジオボタン等の部品の非選択状態の色
// Unselected colors for parts such as checkboxes and radio buttons
$tc-parts-sub3: #bdbec1;
// トグルスイッチのボタンの背景の色など。
// The background color of the toggle switch button, etc.
$tc-parts-sub4: #a8a9ad;
// セレクトボックスやデートピッカーなどの薄い灰色。
// Light gray for select boxes and date pickers.
$tc-parts-sub5: #eee;
// ボーダー関連のボーダー（ダーク）と同様の薄い灰色。
// Light gray similar to border-related borders (dark).
$tc-parts-sub6: #777;
$tc-parts-filter1: rgb(0 0 0 / 50%);
$tc-parts-filter2: rgb(255 255 255 / 50%);
$tc-parts-color1: $tc-sts-color1;
$tc-parts-color2: $tc-sts-color2;

// For Menu Color
$tc-menu-bg-base: #262a33;
$tc-menu-bg-1st-layer: #384148;
$tc-menu-bg-2nd-layer: #436b79;

// For Scrollbar Color
$tc-scrollbar-face: $tc-parts-accent;
$tc-scrollbar-track: darken($tc-scrollbar-face, 15%);

// For Tooltip Color
$tc-tooltip-black: #444;
$tc-tooltip-grey: #999;
$tc-tooltip-white: #fff;

// For FilterableComboBox Color
$tc-filterable-combobox-selected: darken($tc-parts-base, 15%);

// For Table Color
$tc-table-filter-row: desaturate(lighten($tc-parts-accent, 40%), 40%);
$tc-table-selected-row: desaturate(lighten($tc-parts-accent, 50%), 50%);

// For List Color
$tc-list-bg-base: #fbfbfb;
$tc-list-bg-sub1: #f4f4f4;
$tc-list-bg-sub2: #f7f7f7;

// Hover Color
$tc-font-accent-hover: darken($tc-font-accent, 15%);
$tc-parts-base-hover: darken($tc-parts-base, 10%);
$tc-parts-accent-hover: lighten($tc-parts-accent, 5%);
$tc-parts-sub1-hover: darken($tc-parts-sub1, 10%);
$tc-parts-sub3-hover: lighten($tc-parts-sub3, 5%);
$tc-parts-sub4-hover: darken($tc-parts-sub4, 10%);
$tc-parts-sub5-hover: darken($tc-parts-sub5, 10%);
$tc-parts-sub6-hover: darken($tc-parts-sub6, 10%);

// For Package status color
$tc-pkg-trial: #53a8cb;
$tc-pkg-paid: #27ae60;
$tc-pkg-payment-pending: #f7a43f;
$tc-pkg-expired: #e74c3c;
$tc-pkg-canceled: #bdbeba;

// For Accordion List Card Color
$tc-accordion-bg-edit: desaturate(lighten($tc-parts-accent, 50%), 50%);

// For General Color
$tc-general-grey: #bdbec1;

// For Equipment panel/Control panel Color
$tc-panel-border-color: #d3d4d6;
$tc-control-panel-bg-color: rgba($tc-parts-sub1, 0.9);
$tc-control-panel-tile-bg-color: rgba($tc-parts-base, 0.7);

// For Tabs Color
$tc-font-tabs: #616568;

// For General Picker Background Color
$general-picker-background-color: rgb(154 154 154 / 25%);

// Theme Color (end)
//////////////////////////////////////////////////

// Font Size
$font-size-xxsmall: 10px;
$font-size-xsmall: 11px;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-large-middle: 16px;
$font-size-large: 18px;
$font-size-xlarge: 22px;
$font-size-xxlarge: 24px;
$font-size-huge: 42px;

// Font Weight
$font-weight-regular: 400;
$font-weight-bold: 700;

// Z Indices
$z-index-overlay-dialog: 90;
$z-index-menu: 100;
$z-index-account-panel: 100;
$z-index-cookie-bar: 200;
$z-index-drawer: 300;
$z-index-dialog: 400;
$z-index-popup: 450;
$z-index-loader: 500;
$z-index-toast: 600;

// Header Component
$header-height: 68px;
$header-height-tablet: 48px !default;

// Menu Component
$menu-width: 220px;
$menu-inactive-width: 50px;
