//////////////////////////////////////////////////
// ResetCSS後に、共通的に全体に適用したいスタイルを定義する。
// After ResetCSS, define the style that you want to apply to the whole in common.
//////////////////////////////////////////////////

@import 'variables';

// 日本語
// Japanese
@font-face {
  font-family: NotoSansCJKjp;
  src:
    url('../../assets/fonts/NotoSansCJKjp-Regular.woff2') format('woff2'),
    url('../../assets/fonts/NotoSansCJKjp-Regular.ttf') format('truetype');
}

@font-face {
  font-family: NotoSansCJKjp;
  src:
    url('../../assets/fonts/NotoSansCJKjp-Bold.woff2') format('woff2'),
    url('../../assets/fonts/NotoSansCJKjp-Bold.ttf') format('truetype');
  font-weight: $font-weight-bold;
}

// 英語
// English
@font-face {
  font-family: NotoSans;
  src:
    url('../../assets/fonts/NotoSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: NotoSans;
  src:
    url('../../assets/fonts/NotoSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: $font-weight-bold;
}

html {
  height: 100vh;
}

body {
  height: 100vh;
  font-size: $font-size-base;
  font-family: NotoSans, NotoSansCJKjp, sans-serif;
  color: $tc-font-base;
  -webkit-text-size-adjust: 100%;

  // 画面の横幅を超える横棒グラフを押下した際に画面親要素が横幅を超えてツールチップが表示されてしまう問題の修正
  // Fixed an issue where the screen parent element would exceed the width of the screen and display a tooltip when a horizontal bar graph that exceeds the width of the screen is pressed.
  &.popup-tooltip-style {
    .k-animation-container {
      width: 300px;
    }

    .k-popup .k-chart-tooltip {
      padding-inline: 9px;
    }
  }
}

a {
  text-decoration: none;
}

button {
  font-family: NotoSans, NotoSansCJKjp, sans-serif;
  cursor: pointer;
  color: $tc-font-base;
}

input:disabled,
textarea:disabled {
  color: inherit;
}

::selection {
  background-color: #ccc !important;
  color: inherit !important;
}

.disabled {
  opacity: .5 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.google-tag-manager {
  height: 0;
  width: 0;
  display: none;
  visibility: hidden;
}

.icon-button {
  background: transparent;
  border: 0;
  padding: 0;
  width: 20px;
  height: 20px;
}

// カスタムアコーディオンリストカード 可変ラベル
// Custom accordion list card variable label
.custom-accordion-list-card__variable-label {
  word-break: break-word;
}

// テーブル部品共通スタイル
// Common style for table parts
.k-grid-header,
.k-grid td,
.k-grid th,
// kendoGridDetailTemplate対応
// kendoGridDetailTemplate correspondence
.k-grid .k-hierarchy-cell {
  border: 0;
  text-align: left;
  text-overflow: initial;
  word-break: break-word;
}

* {
  word-break: break-word;
}

[class*="k-link"][class*="k-state-disabled"] {
  color: inherit !important;
}

/* stylelint-disable-next-line */
kendo-popup {
  z-index: $z-index-popup !important;
}
